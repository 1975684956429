<template>
  <b-modal v-model="modal" size="xl" hide-footer centered>
    <template #modal-title> LOBBY </template>
    <b-row>
      <b-col sm="12" md="6">
        <div class="mb-3">
          <label>LOGO</label>
          <vs-input v-model="form.logo" border class="shadow-lg" />
        </div>
      </b-col>
      <b-col sm="12" md="6">
        <div class="mb-3">
          <label>BACKGROUND</label>
          <vs-input v-model="form.background" border class="shadow-lg" />
        </div>
      </b-col>
      <b-col sm="12" md="6">
        <div class="mb-3">
          <label>IMAGEN DE CRUPIER</label>
          <vs-input v-model="form.cruppierLogo" border class="shadow-lg" />
        </div>
      </b-col>
      <b-col sm="12" md="6">
        <div class="mb-3">
          <label>LOADER LOGO</label>
          <vs-input v-model="form.loaderLogo" border class="shadow-lg" />
        </div>
      </b-col>
      <b-col sm="12" md="6">
        <div class="mb-3">
          <label>COLOR PRIMARIO</label>
          <div class="flex">
            <vs-input
              v-model="form.primaryColor"
              border
              class="shadow-lg w-50 d-inline-block" />
            <b-form-input
              v-model="form.primaryColor"
              id="primary-color"
              type="color"
              class="border-0 shadow-lg w-50 d-inline-block" />
          </div>
        </div>
      </b-col>
      <b-col sm="12" md="6">
        <div class="mb-3">
          <label>COLOR SECUNDARIO</label>
          <div class="flex">
            <vs-input
              v-model="form.secondaryColor"
              border
              class="shadow-lg w-50 d-inline-block" />
            <b-form-input
              v-model="form.secondaryColor"
              id="secondary-color"
              type="color"
              class="border-0 shadow-lg w-50 d-inline-block" />
          </div>
        </div>
      </b-col>
      <b-col sm="12" md="12">
        <div class="mb-3">
          <vs-checkbox v-model="form.useLogo" ref="status">
            USAR LOGOS DEL OPERADOR
          </vs-checkbox>
        </div>
      </b-col>
    </b-row>
    <div class="d-flex justify-content-center">
      <vs-button @click="save">GUARDAR</vs-button>
      <vs-button @click="closeModa" success>CANCELAR</vs-button>
    </div>
  </b-modal>
</template>

<script>
import Swal from "sweetalert2";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      modal: false,
      form: {
        logo: "",
      },
      operator: {},
    };
  },
  computed: {
    ...mapGetters({
      success: "operators/getSuccess",
      envs: "notification/mapEnvs",
      getErrors: "notification/getErrors",
    }),
  },
  methods: {
    async save() {
      try {
        await this.update({
          doc: {
            ...this.operator,
            ...this.form,
          },
        });
        if (!this.success)
          return Swal.fire({
            position: "center",
            icon: "error",
            title: "Error actualizando el operador",
            showConfirmButton: false,
            timer: 1500,
          });

        Swal.fire({
          position: "center",
          icon: "success",
          title: "Operador guardado con exito",
          showConfirmButton: false,
          timer: 1500,
        });

        this.checkErrors();

        this.closeModa();
        this.$emit("closeModal");
      } catch (error) {
        console.log("ERROR EDIT OPERATOR", error);
      }
    },
    closeModa() {
      this.modal = false;
      this.form = {};
    },
    checkErrors() {
      if (this.getErrors.length) {
        this.getErrors.map(({ api, reason }) => {
          const message = `API: ${this.envs[api]} | REASON: ${reason}`;
          this.openWinNotification(null, "warning", "ERROR", message);
        });
      }
    },
    openWinNotification(position = null, color, title, text, duration = 10000) {
      this.$vs.notification({
        color,
        position,
        title,
        text,
        duration,
        progress: "auto",
      });
    },
    ...mapActions({
      update: "operators/update",
    }),
  },
};
</script>

<style lang="sass" scoped></style>
