<template>
  <b-modal v-model="modal" size="xl" hide-footer centered>
    <p>
      <strong> NUMERO SELECCIONADO: {{ currentNumber }} </strong>
    </p>
    <div class="provider-error-container" v-if="getErrorCodes.length">
      <div
        v-for="(el, i) in getErrorCodes"
        :key="i"
        class="d-flex align-items-center">
        <vs-button class="me-2" @click="currentNumber = Number(el.result)">{{
          Number(el.result)
        }}</vs-button>
        <p class="m-0">{{ el.title.toUpperCase() }}</p>
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <vs-button @click="save">GUARDAR</vs-button>
      <vs-button @click="closeModal" success>CANCELAR</vs-button>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  emits: ["addResult"],
  data() {
    return {
      modal: false,
      currentNumber: null,
    };
  },
  computed: {
    ...mapGetters({
      getErrorCodes: "providerErrors/getErrorCodes",
    }),
  },
  methods: {
    save() {
      this.$emit("addResult", this.currentNumber);
      this.closeModal();
    },
    closeModal() {
      this.modal = false;
      this.currentNumber = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.provider-error-container {
  height: 80vh;
  overflow-y: scroll;
}
</style>
