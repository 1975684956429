<template>
  <b-modal id="bv-modal-example" v-model="modal" size="xl" hide-footer centered>
    <template #modal-title>
      {{ $t("operators.roulettes") }}: {{ operator.name }}
    </template>
    <div>
      <div>
        <div>
          <b-table striped hover :items="item" :fields="makeFields()">
            <template #cell(pleno)="data">
              <vs-button
                @click="handleBetLimit('pleno', data.item)"
                v-if="data.item.pleno">
                {{ data.item.pleno }}
              </vs-button>
            </template>
            <template #cell(semipleno)="data">
              <vs-button
                @click="handleBetLimit('semipleno', data.item)"
                v-if="data.item.semipleno">
                {{ data.item.semipleno }}
              </vs-button>
            </template>
            <template #cell(calle)="data">
              <vs-button
                @click="handleBetLimit('calle', data.item)"
                v-if="data.item.calle">
                {{ data.item.calle }}
              </vs-button>
            </template>
            <template #cell(cuadro)="data">
              <vs-button
                @click="handleBetLimit('cuadro', data.item)"
                v-if="data.item.cuadro">
                {{ data.item.cuadro }}
              </vs-button>
            </template>
            <template #cell(linea)="data">
              <vs-button
                @click="handleBetLimit('linea', data.item)"
                v-if="data.item.linea">
                {{ data.item.linea }}
              </vs-button>
            </template>
            <template #cell(columna)="data">
              <vs-button
                @click="handleBetLimit('columna', data.item)"
                v-if="data.item.columna">
                {{ data.item.columna }}
              </vs-button>
            </template>
            <template #cell(docena)="data">
              <vs-button
                @click="handleBetLimit('docena', data.item)"
                v-if="data.item.docena">
                {{ data.item.docena }}
              </vs-button>
            </template>
            <template #cell(chanceSimple)="data">
              <vs-button
                @click="handleBetLimit('chanceSimple', data.item)"
                v-if="data.item.chanceSimple">
                {{ data.item.chanceSimple }}
              </vs-button>
            </template>
            <template #cell(color)="data">
              <vs-button
                @click="handleBetLimit('color', data.item)"
                v-if="data.item.chanceSimple">
                {{ data.item.chanceSimple }}
              </vs-button>
            </template>
            <template #cell(even_odd)="data">
              <vs-button
                @click="handleBetLimit('even_odd', data.item)"
                v-if="data.item.chanceSimple">
                {{ data.item.chanceSimple }}
              </vs-button>
            </template>
            <template #cell(cubre)="data">
              <vs-button
                @click="handleBetLimit('cubre', data.item)"
                v-if="data.item.cubre">
                {{ data.item.cubre }}
              </vs-button>
            </template>
            <template #cell(specialCalle)="data">
              <vs-button
                @click="handleBetLimit('specialCalle', data.item)"
                v-if="data.item.specialCalle">
                {{ data.item.specialCalle }}
              </vs-button>
            </template>
            <template #cell(template)="data">
              {{ data.item.template }}
            </template>
            <template
              #cell(actions)="data"
              v-if="['ADMIN', 'CLIENT'].includes(role)">
              <b-dropdown variant="link" no-caret>
                <template #button-content>
                  <i class="fa fa-fw fa-bars font-size-16" />
                </template>
                <b-dropdown-item @click="edit(data.item)">
                  <i class="uil-edit m-2 font-size-18" />
                  <span class="align-middle ml-5">Editar</span>
                </b-dropdown-item>
                <b-dropdown-item @click="editLayout(data.item)">
                  <i class="uil-document-layout-center m-2 font-size-18" />
                  <span class="align-middle ml-5">Cambiar layout</span>
                </b-dropdown-item>
                <b-dropdown-item @click="editTemplate(data.item)">
                  <i class="uil-document-layout-center m-2 font-size-18" />
                  <span class="align-middle ml-5">Cambiar template</span>
                </b-dropdown-item>

                <b-dropdown-item @click="addCurrency(data.item)">
                  <i class="uil-coins m-2 font-size-18" />
                  <span class="align-middle ml-5"
                    >{{ $t("helpers.add") }} {{ $t("filter.currency") }}</span
                  >
                </b-dropdown-item>

                <b-dropdown-item @click="configLimits(data.item)">
                  <i class="uil-file-alt m-2 font-size-18" />
                  <span class="align-middle ml-5">{{
                    $t("actions.subItems.configLimits")
                  }}</span>
                </b-dropdown-item>

                <b-dropdown-item @click="deleteRoulette(data.item)">
                  <i class="uil-trash m-2 font-size-18" />
                  <span class="align-middle ml-5">Eliminar</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
        </div>
      </div>
    </div>
    <EditRoulette
      :roulette="roulette"
      :operator="operator"
      ref="EditRoulette"
      @closeModal="closeModa" />
    <LimitsByBets
      ref="limitsByBets"
      :betType="betType"
      :betsWithoutLimits="betsWithoutLimits"
      :betsLimits="betsWithLimits"
      :operator="operator"
      :roulette="roulette">
    </LimitsByBets>
    <LayoutRoulette ref="LayoutRouletteComponent" @closeModal="closeModa" />
  </b-modal>
</template>

<script>
import Swal from "sweetalert2";
import EditRoulette from "./editRoulette.vue";
import { mapActions, mapGetters } from "vuex";
import LimitsByBets from "./LimitsByBets.vue";
import { formatBetLimits } from "../../helpers/formatBetLimits";
import LayoutRoulette from "./LayoutRoulette.vue";
import { TEMPLATE_WEB_OPTIONS } from "../../../../../helpers/WEB_IMPLEMENTATIONS";
export default {
  components: { EditRoulette, LimitsByBets, LayoutRoulette },
  props: {
    item: {
      type: Array,
      default: () => {
        return [];
      },
    },
    operator: Object,
    role: String,
  },
  data() {
    return {
      modal: false,
      roulettes: [],
      roulette: {},
      fields: [],
      betType: "",
      betsWithoutLimits: [],
      betsWithLimits: [],
    };
  },
  computed: {
    ...mapGetters({
      success: "operators/getSuccess",
      limits: "operators/getLimits",
    }),
  },
  methods: {
    async handleBetLimit(betType, opRoulette) {
      await this.fetchOpertorRouletteLimits({
        operatorId: this.operator._id,
        rouletteId: opRoulette._id,
      });

      if (!this.success)
        return Swal.fire("Verifique que haya asignado los limites generales");

      const { withLimits, withOutLimits } = formatBetLimits(
        this.limits,
        betType
      );

      this.betsWithoutLimits = withOutLimits;
      this.betsWithLimits = withLimits;
      this.betType = betType;
      this.roulette = opRoulette;
      this.$refs.limitsByBets.modal = true;
    },
    makeFields() {
      const globalFields = [
        {
          key: "name",
          label: this.$t("filter.roulette"),
          class: "text-center",
        },
        {
          key: "pleno",
          class: "text-center",
        },
        {
          key: "semipleno",
          class: "text-center",
        },
        {
          key: "calle",
          class: "text-center",
        },
        {
          key: "cuadro",
          class: "text-center",
        },
        {
          key: "linea",
          class: "text-center",
        },
        {
          key: "columna",
          class: "text-center",
        },
        {
          key: "docena",
          class: "text-center",
        },
        {
          key: "chanceSimple",
          class: "text-center",
        },
        {
          key: "color",
          class: "text-center",
        },
        {
          key: "even_odd",
          class: "text-center",
        },
        {
          key: "cubre",
          class: "text-center",
        },
        {
          key: "specialCalle",
          class: "text-center",
        },
        {
          key: "template",
          class: "text-center",
        },
      ];
      const adminFields = [
        {
          key: "actions",
          sortable: false,
          label: this.$t("actions.text"),
          class: "align-middle  text-center",
        },
      ];
      if (["ADMIN", "CLIENT"].includes(this.role)) {
        return [...globalFields, ...adminFields];
      }

      return globalFields;
    },
    closeModa() {
      this.modal = false;
    },
    edit(item) {
      this.roulette = { ...item };
      this.$refs.EditRoulette.modal = true;
    },
    editLayout(item) {
      this.$refs.LayoutRouletteComponent.game = item;
      this.$refs.LayoutRouletteComponent.operatorId = item.operator._id;
      this.$refs.LayoutRouletteComponent.layout = item.layout;
      this.$refs.LayoutRouletteComponent.modal = true;
    },
    async editTemplate(item) {
      const { value: template } = await Swal.fire({
        title: "Selecciona un template",
        input: "select",
        inputOptions: TEMPLATE_WEB_OPTIONS,
        inputPlaceholder: "Selecciona un template",
        showCancelButton: true,
      });

      if (!template) return;

      await this.modifyOpRoulette({
        opId: item.operator._id,
        rouId: item._id,
        model: { template },
      });

      if (!this.success) return Swal.fire("ERROR ACTUALIZANDO", "", "error");

      Swal.fire("RULETA ACTUALIZADA", "", "success");
    },
    async deleteRoulette(item) {
      const id = item.operatorRoulette;
      const { isConfirmed } = await Swal.fire({
        title: "¿Estas seguro de eliminar esta ruleta?",
        icon: "info",
        showDenyButton: true,
      });

      if (!isConfirmed) return;

      try {
        const { data } = await this.$http.delete(
          `operators/${id}/delete-roulette`
        );

        if (!data.ok) return Swal.fire("Error eliminando ruleta", "", "info");

        Swal.fire("Ruleta eliminada", "", "success");
      } catch (error) {
        console.log("ERROR DELETE ROULETTE", error);
      }

      this.closeModa();
    },
    addCurrency(item) {
      this.$emit("addCurrencyRoulette", item);
    },
    configLimits(item) {
      this.$emit("configLimits", item);
    },
    ...mapActions({
      fetchOpertorRouletteLimits: "operators/fetchOpertorRouletteLimits",
      modifyOpRoulette: "operators/modifyOpRoulette",
    }),
  },
};
</script>

<style>
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 111%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #f6f6f6;
  border-radius: 0.4rem;
  outline: 0;
}
</style>
