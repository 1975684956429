<template>
  <Layout>
    <PageHeader :title="title" moda_title="Reporte de Jackpots" />
    <ShowDetails
      :item="dataDetails"
      :items="betItems"
      :result="roundResult"
      :jackpots="jackpots"
      :plenoAmount="plenoAmount"
      ref="showDetails" />
    <showWheelBet ref="showWheelBetComponent" :item="dataDetails" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-md-3 col-sm-9">
                <label class="d-inline-flex align-items-center w-100">
                  <span class="m-2">{{ $t("filter.from") }}: </span>
                  <vs-input v-model="filterData.fromDate" type="date" />
                  <vs-input
                    class="ms-2"
                    type="time"
                    v-model="filterData.fromHour"
                    v-if="filterData.fromDate" />
                </label>
              </div>
              <div class="col-md-3 col-sm-9">
                <label class="d-inline-flex align-items-center w-100">
                  <span class="m-2">{{ $t("filter.until") }}: </span>
                  <vs-input v-model="filterData.toDate" type="date" />
                  <vs-input
                    class="ms-2"
                    type="time"
                    v-model="filterData.toHour"
                    v-if="filterData.toDate" />
                </label>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-sm-12 col-md-4" v-if="checkAdmins()">
                <label class="d-inline-flex align-items-center w-100">
                  {{ $t("filter.client") }}:
                  <select
                    v-model="filterData.clientId"
                    @change="getOperatorClient(true)"
                    name="client"
                    class="form-select form-select-sm m-2 border-0 shadow-lg"
                    placeholder="Seleccione..">
                    <option disabled selected>
                      {{ $t("helpers.select") }}...
                    </option>
                    <option
                      v-for="(item, index) in clients"
                      :key="index"
                      :value="item._id">
                      {{ item.name }}
                    </option>
                  </select>
                </label>
              </div>
              <div
                class="col-sm-12 col-md-4"
                v-if="userRole !== 'OPERATOR' && filterData.clientId">
                <label class="d-inline-flex align-items-center w-100">
                  {{ $t("filter.operator") }}:
                  <select
                    v-model="filterData.operatorId"
                    @change="getRoulettesOperator"
                    name="client"
                    class="form-select form-select-sm m-2 border-0 shadow-lg"
                    placeholder="Seleccione..">
                    <option disabled selected>
                      {{ $t("helpers.select") }}...
                    </option>
                    <option
                      v-for="(item, index) in operators"
                      :key="index"
                      :value="item._id">
                      {{ item.name }}
                    </option>
                  </select>
                </label>
              </div>
              <div
                class="col-sm-12 col-md-12 d-flex justify-content-start"
                >
                <!-- v-if="userRole !== 'OPERATOR' && filterData.operatorId" -->
                <label
                  class="d-inline-flex align-items-center"
                  style="width: max-content">
                  {{ $t("filter.gameType") }}:
                </label>
                <select
                  v-model="filterData.gameType"
                  name="client"
                  class="form-select form-select-sm my-2 ms-2 border-0 shadow-lg w-50"
                  placeholder="Seleccione..">
                  <option disabled selected>
                    {{ $t("helpers.select") }}...
                  </option>
                  <option
                    v-for="(item, index) in getGamesTypes"
                    :key="index"
                    :value="item.value">
                    {{ item.name }}
                  </option>
                </select>
              </div>
              <div
                class="col-sm-12 col-md-4"
                v-if="filterData.gameType === gamesTypes.Roulette">
                <label class="d-inline-flex align-items-center w-100">
                  {{ $t("filter.roulette") }}:
                  <select
                    v-model="filterData.rouletteId"
                    @change="getCurrencies"
                    name="client"
                    class="form-select form-select-sm m-2 border-0 shadow-lg"
                    placeholder="Seleccione..">
                    <option disabled selected>
                      {{ $t("helpers.select") }}...
                    </option>
                    <option value="*">ALL</option>
                    <option
                      v-for="(item, index) in roulettes"
                      :key="index"
                      :value="item._id">
                      {{ item.name }}
                    </option>
                  </select>
                </label>
              </div>
              <div class="col-sm-12 col-md-4" v-if="filterData.rouletteId">
                <label class="d-inline-flex align-items-center w-100">
                  {{ $t("filter.currency") }}:
                  <select
                    v-model="filterData.currency"
                    name="currency"
                    class="form-select form-select-sm m-2 border-0 shadow-lg"
                    placeholder="Seleccione..">
                    <option disabled selected>
                      {{ $t("helpers.select") }}...
                    </option>
                    <option value="*">ALL</option>
                    <option
                      v-for="item in currencies"
                      :key="item._id"
                      :value="item._id">
                      {{ item.name }}
                    </option>
                  </select>
                </label>
              </div>
            </div>
            <div class="d-flex align-items-center mt-2">
              <!-- <vs-input placeholder="Transaction ID" border class="shadow-lg me-2" v-model="filterData.transactionId" /> -->

              <vs-button>
                <downloadexcel
                  :fetch="exportData"
                  :fields="json_fields"
                  :name="fileName"
                  type="xls">
                  {{ $t("filter.downloadExcel") }}
                </downloadexcel>
              </vs-button>

              <DateRange @handleRadio="handleRadio" />
              <vs-button
                type="button"
                @click="filtrar()"
                :disabled="!Object.entries(filterData).length">
                {{ $t("filter.filter") }}
              </vs-button>
              <vs-button
                type="button"
                success
                @click="cancelFilter()"
                :disabled="!Object.entries(filterData).length">
                {{ $t("filter.cancel") }}
              </vs-button>
            </div>
            <div class="text-center" v-if="fetching">
              <b-spinner type="grow" label="Spinning"></b-spinner>
              {{ $t("helpers.loading") }}...
            </div>
            <div class="row mt-2">
              <div class="col-sm-12 col-md-6 d-inline-flex align-items-center">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center m-0">
                    {{ $t("filter.show") }}&nbsp;
                    <vs-select v-model="perPage" color="dark" class="shadow-lg">
                      <vs-option
                        v-for="(op, index) in pageOptions"
                        :key="index"
                        :label="op"
                        :value="op"
                        >{{ op }}</vs-option
                      >
                    </vs-select>
                    &nbsp;{{ $t("filter.entries") }}
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end">
                  <label class="d-inline-flex align-items-center m-2">
                    {{ $t("filter.search") }}:
                    <vs-input
                      v-model="filter"
                      type="search"
                      primary
                      class="shadow-lg">
                    </vs-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
              <!-- Table -->
              <b-overlay :show="loading" rounded="lg">
                <div class="table-responsive mb-0" v-if="tableData.length">
                  <b-table
                    id="jackpots-table"
                    :items="tableData"
                    :fields="fields"
                    responsive="sm"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    :sort-by="sortBy"
                    :sort-desc="sortDesc">
                    <template #cell(jackpots)="data">
                      <div class="w-100 mt-3 d-flex">
                        <JackpotPill :jackpot="data.item.jackpots" />
                      </div>
                    </template>
                    <template #cell(details)="data">
                      <vs-button @click="openDetails(data.item)">{{
                        $t("helpers.look")
                      }}</vs-button>
                    </template>
                    <template #cell(createdAt)="data">
                      <div
                        class="createdAt"
                        @mouseover="handleOver"
                        @mouseleave="handleLeave">
                        {{
                          moment(data.item.createdAt).format(
                            "DD/MM/YYYY | HH:mm:ss"
                          )
                        }}
                        <span class="badge badge-time bg-primary rounded-pill"
                          >Server time:
                          {{
                            moment
                              .utc(data.item.createdAt)
                              .format("DD/MM/YYYY | HH:mm:ss")
                          }}</span
                        >
                      </div>
                    </template>
                  </b-table>
                </div>
              </b-overlay>
              <p
                class="text-center my-2"
                v-if="searchActive && !tableData.length && !loading">
                {{ $t("tableReports.noResults") }}
              </p>
              <div class="row" v-if="tableData.length">
                <div class="col">
                  <div
                    class="dataTables_paginate paging_simple_numbers float-end">
                    <ul class="pagination pagination-rounded mb-0">
                      <button
                        class="btn btn-secondary mx-2 uil-angle-left"
                        @click="beforePage"
                        :disabled="!skip"></button>
                      <button
                        class="btn btn-primary mx-2 uil-angle-right"
                        @click="nextPage"
                        :disabled="
                          tableData.length > limit || tableData.length < limit
                        "></button>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import downloadexcel from "vue-json-excel";
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header-simple";
import DateRange from "../components/DateRange.vue";
import ShowDetails from "../transactions/show.vue";
import { onlyAdmins } from "../../../../router/guards/roles";
import Swal from "sweetalert2";
import { JackpotPill } from "../../../../components";
import { showWheelBet } from "../transactions/components";

export default {
  components: {
    Layout,
    PageHeader,
    downloadexcel,
    DateRange,
    ShowDetails,
    JackpotPill,
    showWheelBet,
  },
  data() {
    return {
      title: "Jackpots",

      // ROLES
      readAllRoles: onlyAdmins,

      // USER DATA
      userData: {},
      userRole: "",

      // FILTER
      filterData: {},
      clients: [],
      operators: [],
      roulettes: [],
      currencies: [],

      // EXPORT DATA
      json_fields: {
        [this.$t("transactions.roundID")]: "round.identifierNumber",
        [this.$t("tableReports.casinoName")]: "game.name",
        [this.$t("tableReports.userId")]: "bet.player.userId",
        [this.$t("betDetail.user")]: "bet.player.username",
        [this.$t("tableReports.operator")]: "operator",
        [this.$t("tableReports.debit")]: "bet.totalAmount",
        [this.$t("tableReports.credit")]: "amount",
        [this.$t("tableReports.currency")]: "currency",
        ["TRANSACTION ID"]: "bet.transactionId",
        [this.$t("tableReports.date")]: "createdAt",
      },
      fileName: "jackpots",

      // FETCH
      fetching: false,
      searchActive: false,
      loading: false,
      // TABLE
      tableData: [],
      fields: [],
      // PAGINATION
      skip: 0,
      limit: 10,
      page: 0,
      perPage: 10,
      pageOptions: [10, 25, 50, 100, 150, 200],
      filter: null,
      filterOn: [],
      sortBy: "createdAt",
      sortDesc: true,
      currentPage: 1,

      // BET DETAIL
      betItems: [],
      jackpots: [],
      plenoAmount: 0,
      roundResult: null,
      dataDetails: {},

      // BETS
      reds: [
        1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 25, 27, 30, 32, 34, 36,
      ],
    };
  },
  computed: {
    ...mapGetters({
      getMapClients: "clients/getClients",
      mapOperators: "operators/getOperators",
      mapOperatorRoulettes: "operators/getRoulettes",
      mapCurrencies: "currencies/getCurrencies",
      getBet: "reports/getBet",
      getResult: "reports/getResult",
      getGamesTypes: "reports/getGamesTypes",
      gamesTypes: "reports/gamesTypes",
      mapJackpots: "reports/mapJackpots",
      mapFields: "reports/getFields",
      getType: "reports/getType",
    }),
  },
  methods: {
    checkAdmins() {
      return this.readAllRoles.includes(this.userRole);
    },
    getUserRole() {
      this.userData = this.$store.getters["user/getUserData"];
      this.userRole = this.userData.rol;

      if (this.userRole === "CLIENT") {
        this.filterData.clientId = this.userData.client;
        this.getOperatorClient();
      }
    },
    async handleRadio(value) {
      this.picked = value;
      switch (this.picked) {
        case this.$t("helpers.onPage"):
          break;
        case this.$t("helpers.today"): {
          const initialDate = new Date().toJSON().split("T")[0];
          this.filterData = {
            ...this.filterData,
            fromDate: initialDate,
            toDate: initialDate,
          };
          break;
        }

        case this.$t("helpers.month"): {
          let from = 1;
          let to = this.daysInMonth(
            new Date().getMonth(),
            new Date().getFullYear()
          );
          const initialDate = new Date().toJSON().split("T")[0].split("-");
          let fromDate = [initialDate[0], initialDate[1], from].join("-");
          let toDate = [initialDate[0], initialDate[1], to].join("-");
          this.filterData = { ...this.filterData, fromDate, toDate };
          break;
        }
        default:
          break;
      }
      return true;
    },
    async getClients() {
      try {
        await this.fetchClients();
        this.clients = this.getMapClients;
      } catch (error) {
        console.log("ERROR GET CLIENTS", error);
      }
    },
    async getOperatorClient(filter) {
      const client = filter ? this.filterData.clientId : this.userData.client;
      try {
        const options = { params: { client } };
        await this.fetchOperators({ options });
        this.operators = this.mapOperators;
      } catch (err) {
        console.log("Error al obtener operadores del cliente", err);
      }
    },
    async getRoulettesOperator() {
      const operatorId = this.filterData.operatorId;

      try {
        await this.fetchOperatorRoulettes({ id: operatorId });

        this.roulettes = this.mapOperatorRoulettes;
      } catch (err) {
        console.log("Error al obtener ruletas del operador", err);
      }
    },
    async getCurrencies() {
      try {
        await this.fetchCurrencies({
          role: this.userData.role.name,
          user: this.userData,
        });
        this.currencies = this.mapCurrencies;
      } catch (err) {
        console.log(err);
      }
    },
    // FILT DATA
    async filtrar() {
      this.searchActive = true;
      this.loading = true;

      try {
        await this.fetchReports({
          gameType: this.filterData.gameType,
          filterData: { ...this.filterData },
          limit: this.limit,
          page: this.currentPage,
        });
        if (!this.mapJackpots) {
          this.loading = false;
          return (this.tableData = []);
        }

        this.tableData = this.mapJackpots;
        this.fields = this.mapFields;
        this.loading = false;
      } catch (error) {
        console.log("ERROR IN FILTRAR JACKPOTS", error);
      }
    },
    async exportData() {
      if (!this.filterData.fromDate || !this.filterData.toDate)
        return Swal.fire(
          "Es necesario seleccionar un rango de fecha",
          "",
          "info"
        );

      this.fetching = true;

      try {
        await this.fetchReports({
          gameType: this.filterData.gameType,
          filterData: this.filterData,
          limit: this.limit,
          page: this.currentPage,
        });

        this.fetching = false;

        if (!this.mapJackpots) return Swal.fire("No data", "", "info");
        const formatted = this.mapJackpots.map((doc) => {
          return {
            ...doc,
            operator: doc.bet.operator[0].name,
            currency: doc.bet.currencies[0].short,
            createdAt: new Date(doc.createdAt).toLocaleString(),
          };
        });
        return formatted;
      } catch (error) {
        console.log("ERROR EXPORT DATA", error);
        Swal.fire("Error descargando el xls", "", "info");
      }
    },
    async openDetails(item) {
      if (!this.$refs.showDetails.modal) {
        this.betItems = [];
        this.jackpots = [];
        this.plenoAmount = 0;
      }

      const type = this.getType(this.filterData.gameType, item);

      switch (type) {
        case this.gamesTypes.Roulette:
          await this.openRouletteBet(item);
          break;
        case this.gamesTypes.WheelFortune:
          await this.openWheelBet(item);
          break;
        default:
          break;
      }
    },
    async openRouletteBet(item) {
      this.jackpots = item.round.jackpot_values;
      this.plenoAmount = item.game.pleno;

      if (item.bet) {
        // item.player
        this.dataDetails = {
          ...item,
          player: item.bet.player.username,
          operator: item.bet.operator[0].name,
          client: item.bet.client[0].name,
        };

        try {
          await this.fechBetDetail({ _id: item.bet._id, type: item.type });

          this.bets = [];

          this.betItems = this.getBet;
          this.roundResult = this.getResult;
        } catch (err) {
          console.log("Error in open detail", err);
        }
      }

      this.$refs.showDetails.modal = true;
    },
    async openWheelBet(item) {
      await this.fetchWheelBet({ item });

      this.dataDetails = {
        ...item,
        gameType: this.getType(this.filterData.gameType, item),
      };

      this.$refs.showWheelBetComponent.modal = true;
    },
    // TOOLS
    daysInMonth(month, year) {
      return new Date(year, month + 1, 0).getDate();
    },
    cancelFilter() {
      this.filterData = {};
      this.currentPage = 1;
      this.searchActive = false;
      this.getUserRole();
    },
    handleOver(e) {
      const element = e.target;
      const badge = element.querySelector(".badge-time");
      if (badge) {
        badge.style.visibility = "visible";
      }
    },
    handleLeave(e) {
      const element = e.target;
      const badge = element.querySelector(".badge-time");
      if (badge) {
        badge.style.visibility = "hidden";
      }
    },
    // PAGINATION
    nextPage() {
      this.skip += this.limit;
      this.currentPage++;
      this.filtrar();
    },
    beforePage() {
      this.skip -= this.limit;
      this.currentPage--;
      this.filtrar();
    },
    ...mapActions({
      fetchClients: "clients/fetchClients",
      fetchReports: "reports/fetchJackpots",
      fetchOperators: "operators/fetchOperators",
      fetchOperatorRoulettes: "operators/getOperatorRoulettes",
      fetchCurrencies: "currencies/fetchCurrencies",
      fechBetDetail: "reports/fechBetDetail",
      fetchWheelBet: "reports/fetchWheelBet",
    }),
  },
  watch: {
    perPage() {
      this.skip = 0;
      this.limit = this.perPage;
      this.filtrar();
    },
    "filterData.fromHour": function (hour) {
      if (!hour) return (this.filterData.fromDateHour = "");
      this.filterData.fromDateHour = `${this.filterData.fromDate} ${hour}`;
    },
    "filterData.toHour": function (hour) {
      if (!hour) return (this.filterData.toDateHour = "");
      this.filterData.toDateHour = `${this.filterData.toDate} ${hour}`;
    },
  },
  mounted() {
    this.getUserRole();
    this.getClients();
  },
};
</script>

<style>
.createdAt {
  position: relative;
}

.badge-time {
  position: absolute;
  left: 0;
  top: -12px;
  visibility: hidden;
}

.space-nowrap {
  white-space: nowrap !important;
}

.black {
  background: #000;
  color: #fff;
  padding: 2px;
  text-align: center;
}

.red {
  background: #f10;
  color: #fff;
  padding: 2px;
  text-align: center;
}

.green {
  background: #00a429;
  color: #fff;
  padding: 2px;
  text-align: center;
}

.jacks {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.badge-jack {
  position: absolute;
  top: -12px;
  right: -14px;
  z-index: 9;
}
</style>
